import { Controller } from "stimulus"

export default class extends Controller {
  static values = { inquiryId: Number }
  static targets = ["alert"]

  close(event) {
    event.preventDefault()

    const alert = this.element
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const params = { method: "PATCH", headers: headers }

    fetch(`/api/v1/inquiries/${this.inquiryIdValue}/close_alert`, params).then(() => {
      alert.style.display = 'none'
    })
  }
}
