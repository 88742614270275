import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "quantityInput",
    "multiplier",
    "warningAlert",
    "form"
  ]

  static values = { lotSize: Number, inquiryId: Number }

  connect() { this.checkQuantity() }

  checkQuantity() {
    const quantity = parseInt(this.quantityInputTarget.value)
    if (isNaN(quantity)) { return }

    if (quantity % this.lotSizeValue === 0) {
      this.hideWarning()
    } else {
      this.showWarning()
    }
  }

  submitForm(event) {
    event.preventDefault()

    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const params = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({ show_alert_type: 'freight_quantity_changed' })
    }

    fetch(`/api/v1/inquiries/${this.inquiryIdValue}/show_alert`, params)
      .then ((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html)
        this.formTarget.requestSubmit()
      });
  }

  hideWarning() {
    if (!this.hasWarningAlertTarget) { return }
    this.warningAlertTarget.classList.add("d-none")
  }

  showWarning() {
    if (!this.hasWarningAlertTarget) { return }
    this.warningAlertTarget.classList.remove("d-none")
  }
}
